<template>
  <div>
    <div class="xieyiin dborder clearfix">
      <div class="xieyibox">
        <h2>区域房地产土地增值税税源测算报告声明</h2>
        <div class="xieyineirong">
          测算依据协同房地产行业大数据、国家自然资源部公布的土地交易信息、互联网公布的房产销售信息、国家建设部公布的单方造价信息等，对指定的区域内存量房地产项目进行税收存量测算。因房地产项目个性化因素，互联网数据精准度因素等原因会导致测算结果存在一定误差，本测算结果仅供决策参考。<br />

          <h3>测算方法说明：</h3>
          以国家自然资源部公示的指定区域内的土地交易数据为基础，清理、筛选有效的市场行为房地产开发地块，获取地块建设指标与经济指标；<br />

          以国家住建部房产交易网、房天下、安居客、搜狐房产等互联网数据源，广泛获取、校准指定区域房价销售信息；<br />

          基于以上数据基础，应用协同大数据房地产项目建设模型、地块投资收益测算模型、建筑工程成本测算模型、房地产项目税负测算模型等对指定区域的房地产进行税负测算，得到指定区域的测算结果。<br />
          <h3>数据情况说明：</h3>
          因无法获取房地产项目开发时地表现状，所有地块默认为净地，计算项目扣除成本时，未考虑拆迁补偿相关费用。<br />
      
        </div>
        <a href="javascript:void(0)" @click="closePage" class="close"
          >关闭</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
      closePage(){
        window.close();
      }
  },
};
</script>
<style scoped lang="less">
.xieyiin {
  width: 700px;
  margin: 0 auto;
  padding: 0 40px;
}
.dborder {
  border: 1px solid #d9d9d9;
}
.clearfix {
  *zoom: 1;
}
.clearfix:after {
    clear: both;
}
.clearfix:before, .clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}
.xieyineirong {
  width: 700px;
  margin: 30px auto;
  font-size: 15px;
  color: #666;
  line-height: 25px;
}
.xieyibox {
  h2 {
    font-size: 30px;
    padding: 30px 0;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
  }
  h3 {
    font-size: 17px;
    text-align: left;
    padding: 20px 0;
    color: #333;
  }
}
a.close {
    display: block;
    text-align: center;
    color: #000;
    padding: 30px 0;
    border-top: 1px solid #d9d9d9;
}
a:link{
    color: #666;
    text-decoration: none;
    font-size: 12px;
    font-family: '微软雅黑','Microsoft Yahei','5FAE8F6F96C59ED1';
}
</style>